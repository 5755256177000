(function ($) {
    $.fn.epMap = function (options) {
        return this.each(function () {
            options = $.extend({
                filter: function () {
                }
            }, options, MAP_DATA);
            var elm = this;
            $(".ep-map .level1", elm).maphilight();
            $(".ep-map .level2", elm).maphilight();
            $(".level2-holder", elm).hide();
            var tooltip = $('<div class="ep-map-tooltip"><div class="ep-map-tooltip-arrow"></div><div class="ep-map-tooltip-content"></div></div>').appendTo($(".ep-map", elm)).hide().mousedown(function (event) {
                if ($(this).data("url")) {
                    location.href = $(this).data("url");
                }
                event.stopPropagation();
            });
            $(elm).mousedown(function () {
                tooltip.hide();
                $(".ep-map-tooltip-point", elm).remove();
            });
            $(".ep-map", elm).mouseleave(function () {
                tooltip.hide();
                $(".ep-map-tooltip-point", elm).remove();
            });

            $(elm).bind("epSelect", function (elm, l, k) {
                $(".ep-map-button" + (l == 1 ? ".level1" : ".level2")).click();
                find(function (obj) {
                    return obj.key == k && obj.level == l - 1;
                });
            });

            function getLevel() {
                return $(".ep-map .level1-holder", elm).is(":visible") ? 0 : 1;
            }

            function getBounds(area) {
                var coords = area.attr("coords");
                var arr = coords.split(","), x, y;
                var b = {left: null, right: null, bottom: null, top: null};
                if (arr.length != 3) {
                    for (var i = 0; i < arr.length; i += 2) {
                        x = parseInt(arr[i]);
                        y = parseInt(arr[i + 1]);
                        b.left = b.left == null ? x : (b.left > x) ? x : b.left;
                        b.right = b.right == null ? x : (b.right < x) ? x : b.right;

                        b.top = b.top == null ? y : (b.top > y) ? y : b.top;
                        b.bottom = b.bottom == null ? y : (b.bottom < y) ? y : b.bottom;

                    }
                } else {
                    x = parseInt(arr[0]);
                    y = parseInt(arr[1]);
                    var r = parseInt(arr[2]);
                    b.left = x - r;
                    b.top = y - r;
                    b.right = x + r;
                    b.bottom = y + r;
                }
                return b;
            }


            var showTooltip = function (event) {

                $(".ep-map-tooltip-point", elm).remove();
                var info = $(this).data("info");
                var point = $(this).data("point");
                var b;
                if (info) {
                    b = getBounds($(this));
                    //$(".ep-map-tooltip-content", tooltip).html("<img alt='" + info.title + "' src='imagelib/mapicon" + info.img + "' /><h3>" + info.title + "<h3/>" + info.text);
                    $(".ep-map-tooltip-content", tooltip).html("<img alt='" + info.title + "' src='" +
                        info.img + "' /><span class='debug'></span>");
                    //                    $(".ep-map-tooltip-content", tooltip).html(info.text);
                    tooltip.data("url", info.url || null);
                    tooltip.css({
                        left: (b.left + (b.right - b.left) / 2 + 15) + "px",
                        top: (b.top + (b.bottom - b.top) / 2 - 7) + "px"
                    }).hide().fadeIn();

                } else if (point && (event.type == "mousedown" || point.isObject === true)) {
                    if (event.type == "mousedown" && point.isObject === true) {
                        if (point.url) {
                            location.href = point.url;
                        }
                    }
                    showPointTooltips($(this));

                } else {
                    tooltip.hide();
                }
                event.preventDefault();
                return false;
            };

            var showPointTooltips = function (area) {
                var l1 = getLevel();
                var levelClass = l1 == 0 ? ".level1-map" : ".level2-map";
                var obj = area.data("point");
                $(".point-text").html(obj.title).show();

                for (var i = 0; i < obj.key.length; i++) {
                    var elm1 = $(levelClass + " area", elm).eq(obj.key[i]);
                    if (elm1.length == 0) {
                        break;
                    }
                    var b = getBounds(elm1);
                    var t = $('<div class="ep-map-tooltip-point"><img src="' +
                        options.path+obj.img + '"></div>').appendTo($(".ep-map", elm));
                    t.css({
                        left: (b.left + (b.right - b.left) / 2 - 15) + "px",
                        top: (b.top - 35) + "px"
                    }).hide().fadeIn();
                    var d1 = elm1.data('maphilight');
                    d1.alwaysOn = true;
                    d1.neverOn = false;
                    elm1.data('maphilight', d1).trigger('alwaysOn.maphilight');
                }
            };

            $("area", elm).mousedown(showTooltip).mouseover(showTooltip);
            //            $("area", elm).mousedown(function(){
            //                console.log($(this).index());
            //            });

            function hideAll() {

                $(".point-text").hide();
                $(".ep-map-tooltip").hide();
                $(".ep-map-tooltip-point").remove();
                $("area", elm).each(function () {
                    var data = $(this).data('maphilight') || {};
                    if ($(this).data("info")) {
                        data.alwaysOn = false;
                        data.neverOn = false;
                        $(this).data('maphilight', data).trigger('alwaysOn.maphilight');
                    } else if ($(this).data("point")) {
                        var p = $(this).data("point");
                        if (p.isObject === true) {
                            data.alwaysOn = true;
                            data.neverOn = false;
                        } else {
                            data.alwaysOn = false;
                            data.neverOn = true;
                        }
                        $(this).data('maphilight', data).trigger('alwaysOn.maphilight');

                    }
                });
            }


            function createShopBox(category, object){
                var shopBox = '<div class="ep-shops-box">';
                shopBox += '<a href="' + object.url + '">';
                shopBox += '<img class="img" src="' + object.img
                    + '" alt="' + object.title + '">';
                shopBox += '<div class="description">';
                shopBox += '<div class="category">' + category.title + '</div>';
                shopBox += '<div class="title">' + object.title + '</div>';
                shopBox += '<div class="phone">' + object.phone + '</div>';
                shopBox += '</div>';
                shopBox += '<div class="plus-icon"></div>';
                shopBox += '</a>';
                shopBox += '</div>';
                return shopBox;
            }

            function find(fn) {
                var level = getLevel();
                var data = level == 0 ? options.level1 : options.level2;
                var levelClass = level == 0 ? ".level1-map" : ".level2-map";
                var showKeys = [];
                hideAll();
                var shopList = [];
                for (var i1 = 0; i1 < data.objects.length; i1++) {
                    for (var i2 = 0; i2 < data.objects[i1].object.length; i2++) {
                        var obj = data.objects[i1].object[i2];
                        obj.category = i1;
                        obj.level = level;
                        obj.color = data.objects[i1].color;
                        if (fn(obj)) {
                            showKeys.push(obj.key);
                            $(levelClass + " area", elm).eq(obj.key).each(function () {
                                var data = $(this).data('maphilight') || {};
                                data.alwaysOn = true;
                                data.neverOn = false;
                                $(this).data('maphilight', data).trigger('alwaysOn.maphilight');
                            }).trigger('alwaysOn.maphilight');
                            shopList.push({
                                html: createShopBox(data.objects[i1], data.objects[i1].object[i2]),
                                title: data.objects[i1].object[i2].title
                            });
                        }
                    }
                }

                data = level == 0 ? options.level2 : options.level1;
                var showKeys2 = [];
                for (i1 = 0; i1 < data.objects.length; i1++) {
                    for (i2 = 0; i2 < data.objects[i1].object.length; i2++) {
                        obj = data.objects[i1].object[i2];
                        obj.category = i1;
                        obj.level = level == 0 ? 1 : 0;
                        obj.color = data.objects[i1].color;
                        if (fn(obj)) {
                            showKeys2.push(obj.key);
                        }
                    }
                }
                if (showKeys.length > 0){
                    $(".ep-shops", elm).empty();
                    shopList.sort(compareShop);
                    for (i = 0; i < shopList.length; i++) {
                        $(".ep-shops", elm).append(shopList[i].html);
                    }
                }

                // options.filter(level + 1, showKeys, showKeys2);
                return {level: level, showKeys: showKeys, showKeys2: showKeys2};
            }

            var shopNameText = $(".shopName", elm).val(options.nameText).val();
            $(".shopName", elm).addClass("inactive").focus(function () {
                if ($(this).is(".inactive")) {
                    $(this).val("").removeClass("inactive");
                }
            }).blur(function () {
                if ($(this).val().length == 0) {
                    $(this).val(shopNameText).addClass("inactive");
                }
            }).keypress(function (e) {
                var q = $(this).val();
                $(".shopType option", elm).eq(0).attr("selected", "selected");
                if (e.which == 13) {
                    if (q.length > 0) {
                        //alert(plainText(q));
                        var r = find(function (obj) {
                            return plainText(obj.text + " " + obj.title).indexOf(plainText(q)) >= 0;
                        });

                        if (r.showKeys.length == 0 && r.showKeys2.length > 0) {
                            var val = $(".shopName", elm).val();
                            $(".ep-map-button" + (r.level == 1 ? ".level1" : ".level2")).click();
                            var e1 = jQuery.Event("keypress");
                            e1.which = 13;
                            $(".shopName", elm).val(val).removeClass("inactive").trigger(e1);
                        }

                    } else {
                        hideAll();
                    }
                }
            });

            function fillMap(level) {


                var l1 = getLevel();
                var levelClass = l1 == 0 ? ".level1-map" : ".level2-map";

                $(levelClass + " area", elm).data('maphilight', {
                    strokeColor: 'BFBFBF'
                });

                $(".shopName", elm).val("").blur();
                $(".ep-bar1", elm).empty();
                for (var i = level.points.length - 1; i >= 0; i--) {
                    $('<a><img src="' + options.path + level.points[i].img + '"></a>').data("key", i)
                        .appendTo($(".ep-bar1", elm)).bind("click mouseenter", function (event) {
                        var key = $(this).data("key");
                        var level = getLevel();
                        var data = level == 0 ? options.level1 : options.level2;
                        var levelClass = level == 0 ? ".level1-map" : ".level2-map";
                        var arr = data.points[key].key;

                        hideAll();
                        for (var i1 = 0; i1 < arr.length; i1++) {
                            $(levelClass + " area", elm).eq(arr[i1]).each(function () {
                                showPointTooltips($(this));
                            });
                        }

                        event.preventDefault();
                        return false;
                    });
                    for (var i3 = 0; i3 < level.points[i].key.length; i3++) {
                        var obj1 = {
                            alwaysOn: false,
                            neverOn: true,
                            strokeColor: '01509C',
                            fillColor: '01509C',
                            fillOpacity: 1
                        };
                        if (level.points[i].isObject === true) {
                            obj1.neverOn = false;
                            obj1.alwaysOn = true;
                        }
                        $(levelClass + " area", elm).eq(level.points[i].key[i3]).addClass("map-point").data("point", level.points[i]).data('maphilight', obj1).trigger('alwaysOn.maphilight');
                    }
                }
                $(".ep-bar2", elm).empty();
                $(".shopType", elm).empty();
                $(".shopType", elm).append('<option value="">' + options.typeText + '</option>');
                var lastColor = "";

                var shopList = [];

                for (i = 0; i < level.objects.length; i++) {
                    lastColor = level.objects[i].color;
                    $('<a>' + level.objects[i].title + '</a>').data("key", i).appendTo($(".ep-bar2", elm)).click(function (event) {
                        event.preventDefault();
                        var key = parseInt($(this).data("key"));
                        $(".shopType", elm).val(key).trigger("change");
                        return false;
                    }).css({background: level.objects[i].color});


                    $(".shopType", elm).append('<option value="' + i + '">' + level.objects[i].title.replace('<br>', ', ') + '</option>');
                    for (var i1 = 0; i1 < level.objects[i].object.length; i1++) {
                        var obj = level.objects[i].object[i1];
                        obj.category = i;
                        obj.color = level.objects[i].color;
                        $(levelClass + " area", elm).eq(level.objects[i].object[i1].key).data('maphilight', {
                            alwaysOn: false,
                            neverOn: false,
                            strokeColor: 'BFBFBF',
                            fillOpacity: 0.8,
                            fillColor: obj.color.substring(1)
                        }).data("info", obj);

                        shopList.push({
                            html: createShopBox(level.objects[i], level.objects[i].object[i1]),
                            title: level.objects[i].object[i1].title
                        });
                    }

                }
                $(".ep-shops", elm).empty();
                shopList.sort(compareShop);
                for (i = 0; i < shopList.length; i++) {
                    $(".ep-shops", elm).append(shopList[i].html);
                }
                $(".ep-bar2", elm).css({background: lastColor});


                hideAll();
            }

            $(".shopType", elm).change(function () {
                $(".shopName", elm).val("").blur();
                var val = $(this).val();
                if (val.length > 0) {
                    find(function (obj) {
                        return obj.category == parseInt(val);
                    });
                } else {
                    hideAll();
                }
            });

            $(".ep-map-button.level1").click(function () {
                hideAll();
                $(".ep-map-button", elm).removeClass("selected");
                $(this).addClass("selected");

                $(".ep-map .level1-holder", elm).show();
                $(".ep-map .level2-holder", elm).hide();
                fillMap(options.level1);
                // options.filter(1, ["all"], []);
                return false;
            });
            $(".ep-map-button.level2").click(function () {
                hideAll();
                $(".ep-map-button", elm).removeClass("selected");
                $(this).addClass("selected");
                $(".ep-map .level2-holder", elm).show();
                $(".ep-map .level1-holder", elm).hide();
                fillMap(options.level2);
                // options.filter(2, ["all"], []);
                return false;
            });

            function letterFilter(range) {
                $(".shopType option", elm).eq(0).attr("selected", "selected");
                $(".shopName", elm).val("").blur();
                var r = find(function (obj) {
                    var l = obj.title.substring(0, 1).toLocaleLowerCase();
                    for (var i = 0; i < range.length; i++) {
                        if (range.charAt(i).toLowerCase() == l) {
                            return true;
                        }
                    }
                    return false;
                });
                if (r.showKeys.length == 0 && r.showKeys2.length > 0) {
                    $(".ep-map-button" + (r.level == 1 ? ".level1" : ".level2")).click();
                    letterFilter(range);
                }
            }

            $('<a>A - F</a>').appendTo($(".ep-map-nameGroup", elm)).click(function () {
                letterFilter("abcčdef");
            });
            $('<a>G - L</a>').appendTo($(".ep-map-nameGroup", elm)).click(function () {
                letterFilter("ghijkl");
            });
            $('<a>M - R</a>').appendTo($(".ep-map-nameGroup", elm)).click(function () {
                letterFilter("mnopr");
            });
            $('<a>S - U</a>').appendTo($(".ep-map-nameGroup", elm)).click(function () {
                letterFilter("sštu");
            });
            $('<a>V - Ž</a>').appendTo($(".ep-map-nameGroup", elm)).click(function () {
                letterFilter("vzž");
            });
            $('<a>XYW 1-9</a>').appendTo($(".ep-map-nameGroup", elm)).click(function () {
                letterFilter("123456789xyw");
            });
            setTimeout(function(){
                $(".ep-map-button.level1").click();
            }, 0);

        });
    };
})(jQuery);


function parseShop(f) {
    return "<div class='ShopList' >\n" +
        "   <a href='" + f.object.url + "'>\n" +
        "       <img src='" + f.object.img + "' alt='" + f.object.title + "' />\n" +
        "   </a>\n" +
        "   <h2>\n" +
        "       <a href='" + f.object.url + "'>" + f.object.title + "</a>\n" +
        "   </h2>\n" +
        "   <a href='" + f.object.url + "' class='HorizontalButton'>\n" +
        "       <img src='images/spacer.gif' alt='" + f.object.title + "' />\n" +
        "   </a>\n" +
        "   <span class='debug'>" + f.object.key + "</span>\n" +
        "</div>\n";
}

function compareShop(a, b) {
    /*if (plainCompare(a.object.title.toLocaleLowerCase()) < plainCompare(b.object.title.toLocaleLowerCase()))
        return -1;
    if (plainCompare(a.object.title.toLocaleLowerCase()) > plainCompare(b.object.title.toLocaleLowerCase()))
        return 1;
    return 0;*/
    return plainCompare(a.title.toLocaleLowerCase()).localeCompare(plainCompare(b.title.toLocaleLowerCase()), "sl-SI");
}

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.search);
    if (results == null)
        return "";
    else
        return decodeURIComponent(results[1].replace(/\+/g, " "));
}

function plainText(t) {
    return t.toLowerCase().replace("&", "").replace("'", "").replace("ü", "u").replace(".", "").replace("č", "c").replace("š", "s").replace("ž", "z").replace("-", " ");
}

function plainCompare(t) {
    return t.toLowerCase().replace("&", "").replace("'", "").replace(".", "").replace("-", " ");
}