function initMap() {

    // Find all map objects in the document
    jQuery('.wkb_google-maps').each(function(i, obj) {
        let $mapObj = jQuery(this);
        let $mapDiv = $mapObj.data("id");

        let location = {
            lat: $mapObj.data("lat"),
            lng: $mapObj.data("lng")
        };

        // Create map
        let map = new google.maps.Map(document.getElementById($mapDiv), {
            zoom: $mapObj.data("zoom"),
            center: location,
            styles: []
        });

        // Create markers
        let marker = new google.maps.Marker({
            position: location,
            map: map
        });
    });

}

window.initMap = initMap;


